<template>
    <div class="customField-wrapper">
        <CustomFieldList
            :finalCustomFields="finalCustomFields"
            @editCustomField="editCustomField"
            @isVisible="visible = true"
            @updateCustomFieldType="updateCustomFieldType"
            @updateCustomFieldProject="updateCustomFieldProject"
        />
    </div>
    <CustomFieldsSidebarComponent
        @customFieldStore="customFieldStore"
        @closeSidebar="handleCloseSidebar"
        :componentDetail="componentDetail && Object.keys(componentDetail).length ? componentDetail : {}"
        :customFieldObject="componentDetail && Object.keys(componentDetail).length ? customFieldObject : {}"
        :isCustomField="visible"
        @handleClose="handleClose()"
        :isType="true"
    />
</template>

<script setup>
    import {defineComponent,ref,computed, watch,inject} from 'vue'
    import { useStore } from 'vuex';
    import CustomFieldList from "../../atom/settingCustomField/customFieldList.vue";
    import { mongodbCrudOperations } from '@/utils/MongoQueries/crudOperations/crudOperations';
    import { dbCollections } from '@/utils/FirebaseCollections';
    import { useToast } from 'vue-toast-notification';
    import { BSON } from 'realm-web';
    import { useI18n } from "vue-i18n";
    const finalCustomFields = computed(() => getters['settings/finalCustomFields']);
    const $toast = useToast();
    const {getters,commit} = useStore();
    const { t } = useI18n();
    
    // inject
    const userId = inject('$userId');
    watch(() => getters['settings/finalCustomFields'],(val) => {
        finalCustomFields.value = val;
    });
    watch(() => getters["settings/customFields"],(val) => {
        CustomFieldData.value = val;
    });

    defineComponent({
        name: "CustomizedInput"
    })  
    const visible = ref(false);
    const componentDetail = ref({});
    const customFieldObject = ref({});
    const CustomFieldData = ref(JSON.parse(JSON.stringify(getters["settings/customFields"])));

    const customFieldStore = (value,isEdit) => {
        if(!isEdit){
            value.global = true;
            value.createdAt = new Date();
            value.updatedAt = new Date();
            value.userId = userId.value;
            value.type = value?.type ? value?.type?.toLowerCase() : 'task';
            const query = {
                type: "insertOne",
                collection: dbCollections.CUSTOM_FIELDS,
                data: [value]
            };
            mongodbCrudOperations(query).then((result)=>{
                value._id = result.insertedId;
                commit("settings/mutateFinalCustomFields", {data: value || {},op: "added"});
                visible.value = false;
                componentDetail.value={};
                customFieldObject.value={};
                $toast.success(t('Toast.Field_Added_Successfully'), {position: 'top-right' })
            }).catch((err)=>{
                $toast.error(err, { position: 'top-right' })
            })
        }else{
            value.updatedAt = new Date();
            const query = {
                type: "updateOne",
                collection: dbCollections.CUSTOM_FIELDS,
                data: [
                    {
                        _id: BSON.ObjectId(customFieldObject.value._id)
                    },
                    {
                        $set:{...value}
                    }
                ]
            };
            mongodbCrudOperations(query).then(()=>{                
                commit("settings/mutateFinalCustomFields", {data: {...customFieldObject.value,...value} || {},op: "modified"});
                visible.value = false;
                componentDetail.value={};
                customFieldObject.value={};
                $toast.success(t('Toast.Field_Updated_Successfully'), {position: 'top-right' })
            }).catch((err)=>{
                $toast.error(err, { position: 'top-right' })
            });
        }
    };
    const updateCustomFieldType = (value,type) => {        
        value.updatedAt = new Date();
        value.type = type
        const query = {
            type: "updateOne",
            collection: dbCollections.CUSTOM_FIELDS,
            data: [
                {_id: BSON.ObjectId(value._id)},
                { $set: { type: type }}
            ]
        };
        mongodbCrudOperations(query).then(()=>{
            commit("settings/mutateFinalCustomFields", {data: {...value} || {},op: "modified"});
            $toast.success(t('Toast.Field_Updated_Successfully'), {position: 'top-right' })
        }).catch((err)=>{
            $toast.error(err, { position: 'top-right' })
        });
    };
    const updateCustomFieldProject = (value) => {        
        value.updatedAt = new Date();
        const query = {
            type: "updateOne",
            collection: dbCollections.CUSTOM_FIELDS,
            data: [
                {_id: BSON.ObjectId(value._id)},
                { $set: value.global ? { projectId: [],global:value.global } : {projectId: value.projectId,global:value.global}}
            ]
        };
        mongodbCrudOperations(query).then(()=>{
            commit("settings/mutateFinalCustomFields", {data: {...value} || {},op: "modified"});
            $toast.success(t('Toast.Field_Updated_Successfully'), {position: 'top-right' })
        }).catch((err)=>{
            $toast.error(err, { position: 'top-right' })
        });
    };
    const handleCloseSidebar = (val,pageIndex) => {
        if(pageIndex === 0) visible.value = val;
        componentDetail.value={};
        customFieldObject.value={};
    };
    const editCustomField = (val) => {
        componentDetail.value = CustomFieldData.value.find((x)=> x.cfType === val.fieldType);
        customFieldObject.value = val;
        if(componentDetail.value){
            visible.value = true;
        }
    };
    const handleClose = () => {
        visible.value = false;
        componentDetail.value = {};
        customFieldObject.value = {};
    };
</script>
<style scoped>
.customField-wrapper {
    padding: 20px;
}
.addcustomfield-btn {
    margin-bottom: 20px;
}
</style>